import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./CaseStudy.css";

const CaseStudy = () => {
	const caseStudies = [
		{
			id: "automation-data-entry",
			title: "Automation Data Entry and Analysis",
			link: "/case-studies/automation-data-entry",
		},
		{
			id: "gpt4-prompt-engineering",
			title: "GPT-4 Prompt Engineering for Intelligent Virtual Assistant Development",
			link: "/case-studies/gpt4-prompt-engineering",
		},
		{
			id: "ai-generated-recipes",
			title: "Smart AI Generated Recipes",
			link: "/case-studies/ai-generated-recipes",
		},
		{
			id: "smart-cooking-assistant",
			title: "Smart Cooking Assistant",
			link: "/case-studies/smart-cooking-assistant",
		},
		{
			id: "retail-web-app",
			title: "Web App for a Retail Company",
			link: "/case-studies/retail-web-app",
		},
		{
			id: "computer-vision-background-removal",
			title: "Computer Vision for Efficient Background Removal",
			link: "/case-studies/computer-vision-background-removal",
		},
		{
			id: "ocr-solution",
			title: "OCR Solution to Ease Document Reading",
			link: "/case-studies/ocr-solution",
		},
	];

	return (
		<Container
			fluid
			className="case-study-container d-flex flex-column justify-content-center align-items-center"
		>
			<Row className="d-flex justify-content-center align-items-center case-study-header">
				<h1 className="display-5 text-center case-study-title">
					Our Case Studies
				</h1>
			</Row>

			<Row className="py-5 px-5">
				{caseStudies.map((study) => (
					<Col md={4} className="mb-4" key={study.id}>
						<Card className="shadow-sm h-100">
							<Card.Body className="d-flex flex-column justify-content-between">
								<Card.Title className="text-center mb-3">
									{study.title}
								</Card.Title>
								{/* <Button
									variant="primary"
									href={study.link}
									className="align-self-center mt-3 read-more-btn"
								>
									Learn More
								</Button> */}
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default CaseStudy;
