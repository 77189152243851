import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Brands.css";

const Brands = () => {
	const settings = {
		dots: false, // Disable dots to save space
		infinite: true, // Infinite scrolling
		speed: 500,
		slidesToShow: 4, // Show 4 brands at a time
		slidesToScroll: 1, // Scroll one brand at a time
		autoplay: true, // Enable autoplay
		autoplaySpeed: 3000, // 3 seconds per slide
		arrows: true, // Show navigation arrows
	};

	return (
		<Slider {...settings} className="my-5">
			<div>
				<img
					src={`${process.env.PUBLIC_URL}/images/brands/example1.svg`}
					alt="brand1"
				/>
			</div>
			<div>
				<img
					src={`${process.env.PUBLIC_URL}/images/brands/example2.svg`}
					alt="brand2"
				/>
			</div>
			<div>
				<img
					src={`${process.env.PUBLIC_URL}/images/brands/example3.svg`}
					alt="brand3"
				/>
			</div>
			<div>
				<img
					src={`${process.env.PUBLIC_URL}/images/brands/example4.svg`}
					alt="brand4"
				/>
			</div>
			<div>
				<img
					src={`${process.env.PUBLIC_URL}/images/brands/example5.svg`}
					alt="brand5"
				/>
			</div>
			<div>
				<img
					src={`${process.env.PUBLIC_URL}/images/brands/example6.svg`}
					alt="brand6"
				/>
			</div>
		</Slider>
	);
};

export default Brands;
